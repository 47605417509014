<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col-md-2 app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      >
        <calendar-sidebar
          @selectedTags="handleSelectedTags"
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :class="{ show: mqShallShowLeftSidebar }"
        />
      </div>

      <!-- Calendar -->
      <div class="col-md-10 position-relative border-right">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="isCalendarOverlaySidebarActive = true"
            />
          </div>
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
            </full-calendar>
          </div>
        </div>
      </div>
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import allLocales from '@fullcalendar/core/locales-all';

import axios from "axios";
import moment from "moment";
import { eventBus } from "../../../main";

export default {
  components: {
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
  },
  data() {
    return {

      calendarOptions: {
      
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        eventClick: this.handleEventClick,
        headerToolbar: {
          start: "prev,next, title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        locale: this.$i18n.locale || 'en',
        events: [],
      },
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      events: [],
      event: {},
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateLocale(newVal);
      }
    },
  },
  created() {
    this.getData();
    eventBus.$on("call-function", this.getData);
  },
  beforeDestroy() {
    eventBus.$off("call-function", this.getData);
  },
  methods: {
    updateLocale(locale) {
      this.calendarOptions.locale = locale;
      // You may need to refetch events or refresh the calendar when locale changes
      // Call the appropriate method here if needed
    },
    getData() {
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/date?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          const events = [];
          console.log("response", response.data.dates);
          response.data.dates.forEach((element) => {
            if (
              (
                element.title != null
              )
               &&(!element.objectif?.isDeleted &&
              !element.todo?.isDeleted &&
              !element.meetingInvitation?.isDeleted &&
              !element.calendar?.isDeleted &&
              !element.subObjectif?.isDeleted ) &&
              (element.todo ||
              element.calendar ||
              element.subObjectif ||
              element.meetingInvitation ||
              element.objectif)
            ) {
              var color = "";
              if (element.type === "subObjectif") {
                color = "#7b2cbf";
              } else if (element.type === "meetingInvitation") {
                color = "#0077b6";
              } else if (element.type === "event") {
                color = "#bc4749";
              } else if (element.type === "todo") {
                color = "#fb8500";
              }

              events.push({
                id: element._id,
                title: element.title,
                start: moment.utc(element.startDate).format("YYYY-MM-DDTHH:mm"),
                end: moment.utc(element.endDate).format("YYYY-MM-DDTHH:mm"),
                color: color,
                allDay:
                  element.type === "event" &&
                  element.calendar &&
                  element.calendar.allDay
                    ? true
                    : false,
                tags: element.tags ? element.tags : [],
              });
            }
          });
          // response.data.dates.forEach((element) => {

          //     var color = "";
          //     if (element.type === "subObjectif") {
          //       color = "#7b2cbf";
          //     } else if (element.type === "meetingInvitation") {
          //       color = "#0077b6";
          //     } else if (element.type === "event") {
          //       color = "#bc4749";
          //     } else if (element.type === "todo") {
          //       color = "#fb8500";
          //     }
          //     // || element.todo.isDeleted || element.meetingInvitation.isDeleted || element.event.isDeleted
          //     // if ( !element.objectif.isDeleted  ) {
          //       events.push({
          //         id: element._id,
          //         title: element.title,
          //         start: moment.utc(element.startDate).format("YYYY-MM-DDTHH:mm"),
          //         end: moment.utc(element.endDate).format("YYYY-MM-DDTHH:mm"),
          //         color: color,
          //         allDay:
          //           element.type === "event" &&
          //           element.calendar &&
          //           element.calendar.allDay
          //             ? true
          //             : false,
          //         tags: element.tags ? element.tags : [],
          //       });
          //     // }
          // });
          this.events = events;
          this.calendarOptions.events = events;
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    handleEventClick(info) {
      const id = info.event._def.publicId;
      axios
        .get("https://backendapinodejs.timecheckit.com/api/date?id=" + id)
        .then((response) => {
          if (response.data.dates.type === "event") {
            eventBus.$emit("open-modal", response.data.dates.calendar);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    handleSelectedTags(tags, nbr) {
      if (tags.length === nbr) {
        this.calendarOptions.events = this.events;
      } else {
        this.calendarOptions.events = this.events.filter((obj) =>
          obj.tags.some((tag) => tags.includes(tag))
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "~@core/scss/base/pages/app-todo.scss";
</style>
<style>
.app-calendar .fc .fc-event .fc-event-main {
  color: white;
}
@media screen and (max-width: 761px) {
  .disabled-responsive {
    display: none;
  }
}
</style>