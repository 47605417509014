<template>
  <div>
    <div
    v-if="data._id"
         class="d-flex justify-content-end align-items-center content-sidebar-header px-2 py-1"
      >
         <b-button
            size="sm"
            :variant="'outline-danger'"
            @click="deleteEvent"
         >
         <feather-icon
            icon="TrashIcon"
            class="cursor-pointer"
         />
            {{$t('Delete')}} 
         </b-button>
        </div>
          <b-form class="p-2 row">
    <div class="col-6">
        <b-form-group :label="$t('Title')"
                      label-for="event-title"
                      invalid-feedback="$t('Title_is_required')"
                      :state="state">
            <b-form-input id="event-title"
                           v-model="event.title"
                           autofocus
                           trim
                           :state="state"
                           :placeholder="$t('Event_Title')"/>
        </b-form-group>
    </div>
    <div class="col-6">
        <b-form-group :label="$t('Tags')"
                      label-for="Tags">
            <v-select v-model="event.tags"
                      :placeholder="$t('Select_Tags')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tagsOptions"
                      label="name"
                      :reduce="(option) => option.value"
                      input-id="tags"
                      multiple>
            </v-select>
            <template #option="{ label }">
                <span class="ml-50 d-inline-block align-middle">
                    {{ label }}
                </span>
            </template>
        </b-form-group>
    </div>
    
    <div class="col-5">
        <b-form-group :label="$t('Start_Date')"
                      label-for="start-date"
                      invalid-feedback="$t('Start_Date_is_required')"
                      :state="state1">
            <flat-pickr v-model="startDate"
                        :placeholder="$t('Date')"
                        class="form-control"
                        :state="state1"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: maxStartDate }">
            </flat-pickr>
        </b-form-group>
    </div>
    
    <div class="col-5">
        <b-form-group :label="$t('End_Date')"
                      label-for="end-date"
                      invalid-feedback="$t('End_Date_is_required')"
                      :state="state2">
            <flat-pickr v-model="endDate"
                        :placeholder="$t('Date')"
                        class="form-control"
                        :state="state2"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minDate: minEndDate }">
            </flat-pickr>
        </b-form-group>
    </div>
    
    <div class="col-2" style="padding-left: 0px; padding-right: 0px; margin-top: 27px!important;">
        <b-form-group>
            <b-form-checkbox v-model="event.allDay" name="check-button" switch inline>
                {{$t('All_Day')}}
            </b-form-checkbox>
        </b-form-group>
    </div>
    
    <div class="col-4">
        <b-form-group :label="$t('Event_URL')"
                      label-for="event-url">
            <b-form-input id="event-url"
                           v-model="event.url"
                           type="url"
                           :placeholder="$t('htttps://www.google.com')"
                           trim>
            </b-form-input>
        </b-form-group>
    </div>
    
    <div class="col-4">
        <b-form-group :label="$t('Add_Friend_or_group')"
                      label-for="add-guests">
            <v-select v-model="guests"
                      :placeholder="$t('Add_Guests')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      :close-on-select="false"
                      :options="guestsOptions"
                      input-id="add-guests"
                      label="name">
            </v-select>
        </b-form-group>
    </div>
    
    <div class="col-4">
        <b-form-group :label="$t('Location')"
                      label-for="event-location">
            <b-form-input id="event-location"
                           v-model="event.location"
                           trim
                           :placeholder="$t('Event_Location')">
            </b-form-input>
        </b-form-group>
    </div>
    
    <div class="col-12">
        <b-form-group :label="$t('Description')"
                      label-for="event-description">
            <vue-editor v-model="event.description"
                        :placeholder="$t('Write_your_description')"
                        :editorToolbar="customToolbar">
            </vue-editor>
        </b-form-group>
    </div>
</b-form>

  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import moment from 'moment';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { eventBus } from '../../../../main';
import { VueEditor } from "vue2-editor";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    VueEditor
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      event : {
        user : JSON.parse(localStorage.getItem("userData"))._id,
        title : '',
        startDate : '',
        endDate : '',
        startTime : '',
        endTime : '',
        url : '',
        description : '',
        location : '',
        allDay : false,
        tags : [],
        groups : [],
        friends : []

      },
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean'],
      ],
      tagsOptions:[],
      guestsOptions:[],
      guests:[],
      startDate : '',
      endDate : '',
      maxStartDate : null,
      minEndDate : null,
      isTitleValid : true,
      isStartDateValid : true,
      isEndDateValid : true,
    };
  },
  watch: {
    'startDate': function (newStartDate) {
      this.minEndDate = newStartDate
    },
    'endDate': function (newEndDate) {
      this.maxStartDate = newEndDate
    },
  },
  computed : {
    state() {
      return this.isTitleValid ? null : false;
    },
    state1() {
      return this.isStartDateValid ? null : false;
    },
    state2() {
      return this.isEndDateValid ? null : false;
    },
  },
  created() {
    this.getTags(); 
    this.getGuests()
    if (this.data._id) {
      this.event.title = this.data.title ? this.data.title : ""
      this.event.tags = this.data.tags.map((element) => ({
        name : element.name,
        value : element._id
      }))
      this.startDate = this.data.date.startDate ? this.data.date.startDate : ""
      this.endDate = this.data.date.endDate ? this.data.date.endDate : ""
      this.event.allDay = this.data.allDay ? this.data.allDay : false
      this.event.url = this.data.url ? this.data.url : ""
      this.event.location = this.data.location ? this.data.location : ""
      this.event.description = this.data.description ? this.data.description : ""
      this.guests = [
          ...this.data.groups.map((element) => ({
            name: element.groupName,
            value: element._id,
            type: "Group"
          })),
          ...this.data.friends.map((element) => ({
            name: element.name,
            value: element._id,
            type: "Friend"
          }))
      ]
    }
  },
  methods: {
    getTags() {
      axios.get("https://backendapinodejs.timecheckit.com/api/tags?user=" + JSON.parse(localStorage.getItem("userData"))._id)
      .then((response) => {
        response.data.data.forEach(element => {
          this.tagsOptions.push({
            name: element.name,
            value: element._id
          }) 
        });
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    getGuests() {
      // axios.get("https://backendapinodejs.timecheckit.com/api/groups?user=" + JSON.parse(localStorage.getItem("userData"))._id)
      // .then((response) => {
      //   response.data.data.forEach(element => {
      //     this.guestsOptions.push({
      //       name: element.groupName,
      //       value: element._id,
      //       type: "Group"
      //     }) 
      //   });
      // })
      // .catch((error) => {
      //   this.errorMessage = error.message;
      // });
      axios.get(
        "https://backendapinodejs.timecheckit.com/api/users?id="+JSON.parse(localStorage.getItem("userData"))._id)
      .then((response) => {
        response.data.data.friendList.forEach(element => {
          this.guestsOptions.push({
            name: element.name,
            value: element._id,
            type: "Friend"
          }) 
        });
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    addEvent(bvModalEvent) {
      this.isTitleValid = this.event.title.trim() !== '';
      this.isStartDateValid = this.startDate.trim() !== '';
      this.isEndDateValid = this.endDate.trim() !== '';
      if (this.isStartDateValid && this.isEndDateValid && this.isTitleValid) {
        this.event.startTime = moment(this.startDate, "YYYY-MM-DD HH:mm").format("HH:mm")
        this.event.endTime = moment(this.endDate, "YYYY-MM-DD HH:mm").format("HH:mm")
        this.event.startDate = moment(this.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD")
        this.event.endDate = moment(this.endDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD")
        this.guests.forEach(element => {
          if (element.type==="Group") {
            this.event.groups.push(element.value)
          }
          else {
            this.event.friends.push(element.value)
          }
        })
        if (this.data._id) {
          this.event.tags.forEach((element, index) => {
            if (element.value) {
              this.event.tags[index] = element.value;
            }
          });
          axios.put("https://backendapinodejs.timecheckit.com/api/calender/"+ this.data._id,this.event)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Event Updated`,
                icon: "EditIcon",
                variant: "success",
              },
            });
            eventBus.$emit('call-function')
          })
          .catch((error) => {
            this.errorMessage = error.message;
          });
        }
        else {
          axios.post("https://backendapinodejs.timecheckit.com/api/calender",this.event)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Event Added`,
                icon: "EditIcon",
                variant: "success",
              },
            });
            eventBus.$emit('call-function')
          })
          .catch((error) => {
            this.errorMessage = error.message;
          });
        }
      }
      else {
        bvModalEvent.preventDefault()
      }
    },
    deleteEvent() {
      this.$swal({
        title: "Are you sure you want to delete this event permanently?",
        text: "You can't undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm !",
        cancelButtonText: "Decline !",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
          .delete(
            "https://backendapinodejs.timecheckit.com/api/calender/" + this.data._id
          )
          .then((response) => {
            this.$emit('delete')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Event Deleted`,
                icon: "EditIcon",
                variant: "success",
              },
            });
            eventBus.$emit('call-function')
          })
          .catch((error) => {
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error while deleting`,
                  icon: "UserIcon",
                  variant: "danger",
                },
            });
          });
        }
      })
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" >
@import "~@core/scss/base/bootstrap-extended/include";
.ql-toolbar.ql-snow+.ql-container.ql-snow{
  height: 50px!important;
}
.quill-container{
  height: 50px;
}
.ql-editor{
  height: 50px;
  min-height: 50px!important;
}
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>