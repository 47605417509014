<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        v-b-modal="'sidebar-add-new-event'"
        @click="data = {}"
      >
       {{ $t('add_event') }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">  {{ $t('Calendars') }}</span>
        </h5>
        <b-form-checkbox v-model="checkAll" class="mb-1">
           {{ $t('view_all') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.value"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
    <b-modal
      ref="addEvent"
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      bg-variant="white"
  :title="data._id ? $t('Update_Event') : $t('Add_Event')"
      shadow
      backdrop
      no-close-on-backdrop
      no-header
      right
      size="lg"
  :ok-title="data._id ? $t('Update') : $t('Add')"
      @ok="add"
    >
    <CalendarEventHandler ref="add" :data="data" @delete="deleteEvent"></CalendarEventHandler>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CalendarEventHandler from "../calendar-event-handler/CalendarEventHandler.vue";
import axios from "axios";
import { eventBus } from '../../../../main';

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    CalendarEventHandler
  },
  data() {
    return {
      selectedCalendars : [],
      calendarOptions : [],
      checkAll : true,
      data : {},
      nbrTags : 0
    }
  },
  watch: {
    selectedCalendars: function(newVal, oldVal) {
      this.checkAll = this.nbrTags === newVal.length
      this.$emit('selectedTags', newVal, this.nbrTags);
    },
    checkAll: function(check) {
      this.setCheckAll(check)
    }
  },
  created() {
    eventBus.$on('open-modal', this.showModal);
    axios.get("https://backendapinodejs.timecheckit.com/api/tags?user=" + JSON.parse(localStorage.getItem("userData"))._id)
      .then((response) => {
        this.nbrTags = response.data.count
        response.data.data.forEach((element, index) => {
          this.calendarOptions.push({
            label: element.name,
            value: element._id,
            color: element.color
          }) 
        });
        this.setCheckAll(this.checkAll)
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
  },
  beforeDestroy() {
    eventBus.$off('open-modal', this.showModal);
  },
  methods: {
    add(bvModalEvent) {
      this.$refs.add.addEvent(bvModalEvent)
    },
    deleteEvent() {
      this.$refs.addEvent.hide();
    },
    showModal(data) {
      this.$refs.addEvent.show();
      this.data = data
    },
    setCheckAll(checked) {
      if(checked) {
        this.selectedCalendars = this.calendarOptions.map((item) => item.value);
      }
    }
  }
};
</script>

<style>
</style>
